
import AddNewPeerGroup from "@/components/modals/AddNewPeerGroup.vue";
import PeerGroups from "@/components/peerGroups/peerGroups.vue";
import GroupsTable from "@/components/peerGroups/groupsTable.vue";
import AreaManager from "@/pages/AreaManager.vue";
import peergroups from "@/services/peergroups";
import addNewRegion from "@/components/modals/AddNewRegion";
import stores from "@/services/stores";

export default {
  components: { AddNewPeerGroup, PeerGroups, AreaManager, GroupsTable, addNewRegion },
  data() {
    return {
      tabs: "peerGroup",
      openModel: false,
      openModelRegion:false,
      peerGroupsAccessibleColumns: [
        {
          name: "peerGroupType",
          align: "center",
          label: this.$store.state.user.translate.peer_group_am_type,
          field: (row) => row.name,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "peerGroupName",
          align: "left",
          label: this.$store.state.user.translate.name,
          field: "peerGroupName",
          sortable: true,
        },
        {
          name: "action",
          label: this.$store.state.user.translate.actions,
          field: "action",
        },
      ],
      dataAccessibleRowsPeers: [],
      dataAccessibleRowsRegion: [],
      dataAccessibleRowsDis: [],
      dataAccessibleRowsArea: [],
      dataAccessibleRows:[],
      storeData: "",
      allPeerGroups: "",
      selectedPeerGroup: "",
      loading: false,
      confirm: false,
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      selected: [],
      filter: "",
      visible: false,
      dataStores:[],
    };
  },
  mounted() {
    this.visible = true;
    this.getAllPeerGroups();
    this.getAllStores();
  },
  methods: {
    closeModel() {
      this.openModel = false;
      this.openModelRegion = false;
    },
    getAllStores() {
      stores.getStores(true).then((response) => {
        this.storeData = response.data.response.stores.reverse();
         var copyStore = JSON.parse(JSON.stringify(this.storeData));
         var copyStoreR = JSON.parse(JSON.stringify(this.storeData));
          let storeOptionList = [
            ...new Map(copyStore.map((item) => [item.locationDetails['region'], item])).values(),
          ];
          let storeOptionListR = [
            ...new Map(copyStoreR.map((item) => [item.locationDetails['district'], item])).values(),
          ];
          this.dataAccessibleRowsRegion = storeOptionList.filter((val) => {
            if(!val.locationDetails?.region) return
            val.titleActual = val.title
            val.title = val.locationDetails?.region
            return val.locationDetails?.region;
          });
          this.dataAccessibleRowsDis = storeOptionListR.filter((val) => {
            if(!val.locationDetails?.district) return
            val.titleActual = val.title
            val.title = val.locationDetails?.district
            return val.locationDetails?.district;
          });
      });
    },
    openPeerGroupModel() {
      this.selectedPeerGroup = null;
      this.openModel = true;
    },
    getAllPeerGroups() {
      this.visible = true;
      this.loading = true;
      peergroups
        .getPeerGroups()
        .then((response) => {
          this.dataAccessibleRows = response.data.response.peerGroups.reverse();
          this.dataStores = response.data.response.stores; // filter fro selected store
          this.dataAccessibleRowsPeers = this.dataAccessibleRows.filter((val) => {
            return val.type == "peerGroup";
          });
          // if(this.dataAccessibleRowsPeers){
          //    this.visible = true;
          //    this.loading = true;
          // }
          // this.dataAccessibleRowsRegion = this.dataAccessibleRows.filter((val) => {
          //   return val.type == "region";
          // });
          //  if(this.dataAccessibleRowsRegion){
          //    this.visible = true;
          //    this.loading = true;
          // }
          // this.dataAccessibleRowsDis = this.dataAccessibleRows.filter((val) => {
          //   return val.type == "district";
          // });
          // if(this.dataAccessibleRowsDis){
          //    this.visible = true;
          //    this.loading = true;
          // }
          // this.dataAccessibleRowsArea = this.dataAccessibleRows.filter((val) => {
          //   return val.type == "areaManager";
          // });
          // if(this.dataAccessibleRowsArea){
          //    this.visible = true;
          //    this.loading = true;
          // }
          this.visible = false;
          this.loading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.visible = false;
          this.loading = false;
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
    },
    deletePeerGroup(peerGroup) {
      this.selectedPeerGroup = peerGroup;
      this.confirm = true;
    },
    confirmDeletePeerGroup() {
      peergroups
        .deletePeerGroup(this.selectedPeerGroup._id)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate.removed_successfully,
            color: "green",
          });
          this.getAllPeerGroups();
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
      this.confirm = false;
    },
    editPeerGroupDialog(peerGroup) {
      peerGroup.stores = this.dataStores.filter(item => peerGroup.storeCodes.includes(item.storeNumber));
      
      this.selectedPeerGroup = peerGroup;
      this.openModel = true;
    },
    editPeerGroupDialogRegion(peerGroup){
      peerGroup.stores = this.storeData.filter(item => peerGroup.locationDetails.region === item.locationDetails.region);
      this.selectedPeerGroup = peerGroup;
      this.openModel = false;
      this.openModelRegion = true;
    },
    editPeerGroupDialogDis(peerGroup){
      peerGroup.stores = this.storeData.filter(item => peerGroup.locationDetails.district === item.locationDetails.district);
      this.selectedPeerGroup = peerGroup;
      this.openModel = false;
      this.openModelRegion = true;
    },
    async updatePeerGroup() {
      this.getAllPeerGroups();
      this.openModel = false;
    },
  },
};
